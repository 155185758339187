<template>
    <div
        :class="{
            [$style['block']]: true,
            [$style['block_two-columns']]: content.length > 1,
            [$style['block_centered']]: centered,
            [$style['block_inverted']]: inverted
        }"
    >
        <div :class="$style['block__container']">
            <h1
                :class="[$style['block__title'], 'sw-title']"
                v-html="title"
            />

            <div
                v-for="(text, index) in content"
                :key="index"
                :class="[$style['block__text'], 'sw-text']"
                v-html="text"
            />
        </div>
    </div>
</template>

<script>
export default {
  name: 'TextInColumns',

  props: {
    title: {
      type: String,
      default: 'Заголовок над изображением'
    },

    content: {
      type: Array,
      default: () => [
        'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      ]
    },

    centered: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style module lang="less">
@import (reference) "./assets/Web.less";

.block {
  display: flex;
  flex-wrap: wrap;
  margin: auto 0;

  @margin-left-desktop: 24px;
  @margin-left-tablet: 16px;
  @margin-left-mobile: 0;

  &_centered {
    justify-content: center;
    text-align: center;
  }

  &_inverted { color: #FFFFFF; }

  &__container {
    display: flex;
    flex-wrap: wrap;
    max-width: 606px;
    overflow: hidden;
    @media screen and (max-width: @bp-mobile-big) {
      max-width: 512px;
    }
    @media screen and (max-width: @bp-mobile-small) {
      flex-direction: column;
      max-width: 100%;
    }
  }

  &_two-columns &__container {
    max-width: 100%;
  }

  &__title {
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
    @media screen and (max-width: @bp-mobile-small) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &_two-columns &__title {
    text-align: center;
    @media screen and (max-width: @bp-mobile-small) {
      text-align: left;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    & + & {
      margin-left: @margin-left-desktop;
      @media screen and (max-width: @bp-mobile-big) {
        margin-left: @margin-left-tablet;
      }
      @media screen and (max-width: @bp-mobile-small) {
        margin-top: 24px;
        margin-left: @margin-left-mobile;
      }
    }
  }

  &_two-columns &__text {
    width: calc(50% - @margin-left-desktop / 2);
    @media screen and (max-width: @bp-mobile-big) { width: calc(50% - @margin-left-tablet / 2); }
    @media screen and (max-width: @bp-mobile-small) { width: 100%; }
  }
}
</style>
